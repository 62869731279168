.global-infoPanel h3{
    padding: 10px;
    justify-content: left;
    justify-self: left;
    align-self: flex-start;
}

.global-infoPanel input{
    padding: 8px;
    border-radius: 10px;
}

.global-infoPanel textarea{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
}


.respostasCadastro{
    flex-direction: column;
    width: 100%;
}

.respostasCadastro div{
    width: 100%;
}

.respostasCadastro input{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
}

.respostasCadastro hr{
    margin-top: 32px;
    margin-bottom: 32px;
}

.addQuestao{
    width: 100%;
}

.campoQuestao{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    margin-top: 16px;
    width: 90%;   
}

.camposQuestaoAdd{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;   
    margin-top: 32px;
    margin-bottom: 64px;
}

.camposQuestaoAdd input{
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-left: 5%;
    width: 90%;   
}

.botaoQuestao{
    width: 20%;
}

.botaoQuestao button{
    margin-left: 10%;
    width: 80%;
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.respostaCorretaOption{
    display: flex;
    align-items: start;
    width: 20%;   
}

.respostaCorretaOption h3{
    width: 20px;
}

.respostaCorretaOption input{
    width: 30%;
    padding: 0;
    height: 100%;
    margin-left: 0;
    margin-top: 30px;
}

.addQuestao{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}
.addQuestao button{
    margin-top: 8px;
}

.sameLine {
    width: 30% !important;
    display: flex;
    flex-direction: row;
}