.respondida{
    background-color: green;
    color: #FFF;
}

.errado{
    background-color: red;
    color: #FFF;
}

.responder{
    background-color: #2F4F4F;
    color: #FFF;
}

.opcoesQuestoes{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
}

.opcaoFiltro{
    display: flex;
    width: 100%;
    justify-content: right;
    margin-right: 20px;
}

.nomeProvaDescricao{
    margin-top: 32px;
    margin-bottom: 32px;
}

.option a{
    cursor: pointer;
}