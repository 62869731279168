.formUsuario{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
} 

.selectUsuario{
    width: 100%;
    height: 100%;
    margin-top: 5%;
    color: black;
}

.selectUsuario p{
    color: white;
}

.respostasUsuarios{
    display: flex;
    flex-direction: column;
    width: 100%;
}