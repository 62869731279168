.criarUsuario{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.criarUsuario h3{
    padding: 10px;
    justify-content: left;
    justify-self: left;
    align-self: flex-start;
}

.criarUsuario input{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
}

/* .criarUsuario button{
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
    width: 30%;
    font-size: 20px;
    background-color: #116feb;
    color: #FFF;
    border-radius: 10px;
    text-decoration:none;
    cursor: pointer;
} */

.tiposProva{
    width: 100%;
    height: 100%;
    color: black;
}

.tiposProva input{
    width: 100%;
}

.tiposProva button{
    margin: 8px;
}

.formularioCadastroProva{
    margin-top: 32px;
    margin-bottom: 32px;
}