.criarUsuario{
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
    width: 100%;
}

.criarUsuario h3{
    padding: 10px;
    justify-content: left;
    justify-self: left;
    align-self: flex-start;
}
.criarUsuario input{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
}