.provas{
    width: 100%;
    max-width: 800px;
}

.provas h4{
    padding: 10px;
}

.botaoBaixarArquivo{
    width: 30% !important;
    height: 100% !important;
    margin-top: 5px !important;
    padding: 3px !important;
}

/* .provas button{
    text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  background-color: #2F4F4F;
  color: #FFF;
  cursor: pointer;
  transition: all 0.5s;
} */

.opcoesProva{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
    width: 100%;
    max-width: 800px;
}

.provaTitle{
    width: 100%;
}

.opcaoFiltro{
    display: flex;
    width: 100%;
    justify-content: right;
    margin-right: 20px;
}

.contextModal{
    display: flex;
    flex-direction: column;    
    background-color: #424242;
    width: 100%;
    color: #fff;
}

.tituloModal{
    display: flex;
    justify-content: left;
    border: 5px;
}

.bodymodal{
    display: flex;
    justify-content: left;
    border: 5px;
} 

.provas a{
    text-decoration: none;
    cursor: pointer;
}

.tituloProva{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: larger;
}

.botoesModalFiltro{
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 16px;
    margin-top: 16px;
}

.filtrosProva{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.filtrosProva h4{
    width: 100%;
    margin-top: 0;
}

.filtrosProva input{
    padding: 8px;
    margin-top: 16px;
    border-radius: 10px;
}

.inputFiltro{
    display: flex;
    flex-direction: row;
    justify-content: initial;
    align-items: start;
    justify-items: start;
}

.inputFiltro input{
    margin-right: 10px;
}

.clickOption{
    cursor: pointer;
}