

.opcoesQuestao{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.opcoesBotoesNavegacao{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
    color: var(--text-color-primary);
}

.opcaoBotaoBefore{
    justify-content: left;
}

.opcaoBotaoAfter{
    justify-content: right;
}

.total{
    width: 100%;
    justify-content: left;
}
.opcaoVerificacao{
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    justify-content: right;
    margin-right: 20px;
}

.opcaoVerificacao h2{
    padding: 5px;
    justify-content: space-between;
    cursor: pointer;
}

.loaddingDiv img{
    width: 10%;
    height: 5%;
}

.descricaoQuestao{
    width: 100%;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
}

.Materia{
    margin-top: 16px;
}

.descricaoQuestao h4, i{
    width: 100%;
    max-width: fit-content;
    align-items: center;
    justify-content: center;
    font-weight: initial;
}

.descricaoQuestao img{
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    display: block;
}

.global-respostasLista{
    padding: 20px;
    justify-items: left;
    border: 1px solid #eaebeb;
}

.respostaLabel{
    display: flex;
    width: 100%;
    flex-direction: row;
}

.respostaLabel h4{
    padding-left: 10px;
}

.respostaLabel img{
    padding-left: 10px;
}

.contextModal{
    display: flex;
    flex-direction: column;    
    background-color: #424242;
    width: 100%;
    color: #fff;
    
}

.tituloModal{
    display: flex;
    justify-content: left;
    border: 5px;
}

.bodymodal{
    display: flex;
    justify-content: left;
    border: 5px;
}

.bodymodalComentario{
    display: flex;
    justify-content: left;
    border: 5px;
    margin-bottom: 16px;
    width: 100%;
}

.bodymodalComentario textarea{
    width: 100%;
    padding: 10px;
}

.botoesModal{
    display: flex;
    justify-content: right;
}

.botoesModalSolicitacao{
    display: flex;
    flex-direction: column;
}

.contextComentarios {
    display: flex;
    flex-direction: column;    
    width: 100%;
    color: var(--text-color-primary);
}

.bodymodalAssunto{
    width: 100%;
}

.bodymodalAssunto input{
    padding: 8px;
    width: 100%;
    margin-bottom: 16px;
}

.fraction {
    display: inline-block;
    font-size: 24px; /* Tamanho da fonte da fração */
}
.numerator {
    border-bottom: 1px solid black; /* Linha horizontal abaixo do numerador */
    padding-bottom: 2px; /* Espaçamento abaixo do numerador */
    border-color: white;
}
.denominator {
    padding-top: 2px; /* Espaçamento acima do denominador */
}
.operator {
    padding-top: 8px;
    margin: 8px;
    font-size: 24px; /* Tamanho da fonte do operador */
    vertical-align: middle; /* Alinhar verticalmente */
}
.conta{
    display: flex;
}