.detalhesHistorico{
    width: 80%;
}

.detalhesHistorico h3{
    padding: 8px;
}

.respostasHistorico{
    width: 80%;
}

.barraPrograsso{
    width: 80%;
}

.detalhesHistorico a{
    cursor: pointer;
}